@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-icons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css";

.e-rte-code-mirror-enabled .rte-code-mirror {
  display: block !important; /* To show the custom source code view. */
}
.e-rte-code-mirror-enabled .e-rte-content {
  display: none !important; /* To hide the editor area when custom source code enabled. */
}
/** Mention template styles **/
.editor-mention-item-template {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.editor-mention-item-template .em-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.editor-mention-item-template .em-avatar {
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
  text-indent: 0px;
  line-height: 13px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.editor-mention-item-template .em-name {
  color: rgb(16, 24, 40);
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 5px;
}
.editor-mention-item-template .em-email {
  color: gray;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}
#toolsRTE_rte-edit-view_options li {
  padding: 10px;
  height: 60px;
}
body[class*="-dark"] .em-name {
  color: #fff !important;
}
/** custom source code styles **/
body[class*="-dark"] .rte-code-mirror .cm-tag {
  color: #00ff00;
}
body[class*="-dark"] .rte-code-mirror .cm-string {
  color: blue;
}
body[class*="-dark"] .rte-code-mirror .cm-attribute {
  color: #f00;
}
body[class*="-dark"] .rte-code-mirror .CodeMirror-gutters,
body[class*="-dark"] .rte-code-mirror .CodeMirror {
  background-color: transparent;
  color: #fff;
}
.sb-header {
  z-index: 100;
}
.sb-content.e-view.hide-header {
  top: 0 !important;
}
.sb-header.e-view.hide-header {
  display: none;
}
.e-upload:has(#rteCustomWordUpload) {
  display: none;
}
