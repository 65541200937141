.faq-container {
  padding: 50px 0px;

  .question {
    box-shadow: 0px 1px 4px -1px rgba($color: #000000, $alpha: 0.2);
    height: 160px;
    width: 100%;
    box-sizing: border-box;
    padding: 15px 20px;
    transition-duration: 0.12s;
    .label {
      font-weight: 500;
      font-size: 14px;
      color: var(--darkest-green);
    }
    .value {
      color: var(--blue-primary);
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
    }
    .icon {
      position: absolute;
    }
  }
  .question-active {
    height: 220px;
  }
  @media screen and (max-width: 900px) {
    .question {
      height: fit-content;
    }
  }
}
