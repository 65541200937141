.blogs-container {
  padding: 40px 0px 120px 0px;
  .header-row {
    .header {
      width: fit-content;
      margin-right: 20px;
      margin-left: 120px;
    }
  }
  .posts {
    .indicators {
      margin-bottom: -70px;
      .indicator {
        border: 1px solid var(--green);
        height: 20px;
        width: 20px;
        transition-duration: 0.86s;
        &[data-active] {
          transform: scale(1.2);
          background-color: var(--green);
        }
      }
    }
    .post {
      margin: 10px;
      height: 380px;
      border-radius: 5px;
      flex-direction: column !important;
      box-sizing: border-box;
      padding: 20px;
      box-sizing: border-box;
      background-size: cover;
      background-color: rgba($color: #000000, $alpha: 0.2);
      background-position: center;
      background-repeat: no-repeat;
      .tag {
        color: var(--white);
      }
      .title {
        font-size: 32px;
        font-weight: 600;
        color: var(--white);
      }
    }
  }
  @media screen and (max-width: 700px) {
    .header-row {
      .header {
        margin-left: 0px;
      }
    }
  }
}
