@import url("./syncfusion.scss");
.create-post-container {
  padding: 40px 0px;
  .top {
    margin-bottom: 40px;
    .left {
      .thumbnail-image {
        height: 130px;
        width: 130px;
        object-fit: contain;
      }
      .thumbnail-blank {
        border: 1px solid var(--dimmer);
        height: 130px;
        width: 130px;
        cursor: pointer;
        transition-duration: 0.12s;
        border-radius: 5px;
        .icon {
          color: var(--dimmed);
        }
        .label {
          color: var(--dimmed);
          font-weight: 500;
          font-size: 15px;
        }
        &:hover {
          opacity: 0.8;
          transform: scale(1.05);
        }
      }
      .title-input {
        width: 400px;
      }
    }
    .right {
      .preview-btn {
        color: var(--blue-primary);
        outline-color: var(--blue-primary);
        border-color: var(--blue-primary);
      }
      .cancel-btn {
        margin-left: 10px;
      }
    }
  }
}
