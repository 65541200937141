.overview-container {
  padding: 50px 0px;

  .items {
    .item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      height: 130px;
      cursor: pointer;
      border: 1px solid var(--mantine-color-gray-2);
      transition-duration: 0.12s;
      .label {
        font-size: 18px;
        margin: 15px 0px;
        color: var(--blue-primary);
        font-weight: 500;
      }
      &:hover {
        border-color: var(--blue-primary);
      }
    }
  }
}
