.forgot-password-container {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 300px;
  width: 450px;
  margin-left: -225px;
  margin-top: -150px;
  .title {
    font-size: (26px);
    font-weight: 700;
    color: var(--blue-primary);
  }

  .controls {
    @media (max-width: var(--mantine-breakpoint-xs)) {
      flex-direction: column-reverse;
    }
  }

  .control {
    @media (max-width: var(--mantine-breakpoint-xs)) {
      width: 100%;
      text-align: center;
    }
  }
  .resend {
    cursor: pointer;
  }
  @media screen and (max-width: 500px) {
    width: 96vw;
    margin-left: -48vw;
  }
}
