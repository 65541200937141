@import url("./fonts.scss");
@import url("./variables.scss");

* {
  font-family: "Poppins", sans-serif;
}
html {
  scroll-behavior: smooth;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}
.align-end {
  align-items: flex-end;
}

.width-100 {
  width: 100%;
}
.justify-between {
  justify-content: space-between;
}

.none-display {
  display: none !important;
}
a {
  text-decoration: none !important;
}
