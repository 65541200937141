:root {
  --blue-primary: #214889;

  --darkest-green: #193238;
  --dimmer: #ced4da;
  --dimmed: #868e96;
  --white: #fff;

  --green: #3ad77e;
  --green-50: #3ad77e7a;
  --dimmer-secondary: #e0e0e0;
  --dimmer-tertiary: #f8f9fa;
  --dimmer-dark: #e9ecef;
  --black-20: rgba(0, 0, 0, 0.2);
}

.bg-blue-primary {
  background-color: var(--blue-primary);
}
