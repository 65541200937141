.login-container {
  height: 100vh;

  align-items: center;
  justify-content: center;
  display: flex;
  .login {
    max-width: 400px;
    width: 95%;
    .logo {
      width: 50px;
      object-fit: contain;
    }
    .title {
      color: var(--blue-primary);
    }
  }
}
