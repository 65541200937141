.dashboard-container {
  margin-top: 80px;
  .page-header-row {
    margin-bottom: 20px;
  }
  .page-header {
    color: var(--darkest-green);
    font-weight: 600;
    font-size: 25px;
  }
}
