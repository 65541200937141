.stats-container {
  padding: 50px 0px;

  .stat {
    box-shadow: 0px 1px 4px -1px rgba($color: #000000, $alpha: 0.2);
    height: 140px;
    width: 100%;
    box-sizing: border-box;
    padding: 15px 20px;
    transition-duration: 0.12s;
    .label {
      font-weight: 500;
      font-size: 14px;
      color: var(--darkest-green);
    }
    .value {
      color: var(--blue-primary);
      font-weight: 700;
      font-size: 32px;
    }
    .icon {
      position: absolute;
    }
  }
  .stat-active {
    height: 220px;
  }
}
