.navbar-container {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  height: 70px;
  background-color: var(--white);
  border-bottom: 1px solid var(--mantine-color-gray-3);
  .navbar {
    .logo-container {
      .logo {
        height: 40px;
        object-fit: contain;
      }
    }
  }
}
